import { createI18n } from "vue-i18n";

const messages = {
  pl: {
    LoginForm: {
      title: "standardy obsługi klienta",
      logInInfo: "Zaloguj się aby wyświetlić zawartość.",
      logIn: "Zaloguj",
      email: "Adres e-mail",
      password: "Hasło",
      loginError: "Niepoprawny adres e-mail lub hasło",
      loginSuccess: "Pomyślnie zalogowano!",
      logout: "Wyloguj",
    },
    MenuElements: {
      basicInformations: "Podstawowe informacje",
      clientServiceStandards: "Standardy obsługi klienta",
      activeClientService: "Aktywna obsługa klienta",
      consultantAppearance: "Wygląd konsultantki",
      islandAppearance: "Wygląd wyspy",
      difficultSituations: "Trudne sytuacje - filmy instruktażowe",
      allEntries: "Wszystkie wpisy",
      greetingInstillingConfidence: "Powitanie, zaufanie i relacje",
      customerNeedsResearch: "Badanie potrzeb klienta",
      activeListening: "Aktywne słuchanie",
      featureAdvantageBenefit: "Cecha zaleta, korzyśc",
      promotions: "Promocje",
      customerObjections: "Obiekcje klienta",
      forYou: "Dla ciebie",
      inspirations: "#Inspiracje",
      live: "#Live",
      cosmoTeam: "#CosmoTeam",
      carrier: "#Kariera",
      quiz: "#Quizy",
      manageUsers: "Zarządzaj użytkownikami",
      manageInspirations: "Zarządzaj inspiracjami",
      addUser: "Dodaj użytkownika",
      product: "Produkt komplementarny",
      saleClose: "Zamknięcie sprzedaży",
      toughCases: "Trudne sprawy",
    },
    Breadcrumbs: {
      mainPage: "Strona główna",
      inspirations: "#inspiracje",
      addInspiration: "dodaj inspiracje",
      service: "Aktywna obsługa klienta",
      consultant: "Wygląd konsultantki",
      island: "Wygląd wyspy",
      difficultSituations: "Trudne sytuacje - filmy instruktażowe",
      relations: "Powitanie, zaufanie i relacje",
      needs: "Badanie potrzeb klienta",
      blogContent: "Wszystkie wpisy sok",
      live: "#Live",
      account: "Konto",
      search: "Wyszukiwarka",
      activeListening: "Aktywne słuchanie",
      advantages: "Cecha, zaleta, korzyść",
      promotions: "Promotions",
      objections: "Obiekcje klienta",
      product: "Produkt komplementarny",
      saleClose: "Zamknięcie sprzedaży",
      toughCases: "Trudne sprawy",
      clientTypes: "Typy klientek",
    },
    Inspirations: {
      title: "#inspiracje",
      subtitle: "Zobacz, co zmalowały nasze Konsultantki :)",
      sortBy: "Sortuj według",
      sendBy: "Nadesłane przez",
      single: "Pojedyńcze",
      brief: "Kratkę",
      showAs: "Wyświetl jako:",
      edit: "Edytuj",
      delete: "Usuń",
      fromOldest: "Daty: Od najstarszych",
      fromNewest: "Daty: Od najnowszych",
      mostLikes: "Polubienia: Najwięcej",
      leastLikes: "Polubienia: Najmniej",
      mostViews: "Wyświetlenia: Najwięcej",
      leastViews: "Wyświetlenia: Najmniej",
      inspirationRejected: "Inspiracja odrzucona",
      originalInspiration: "Oryginalna inspiracja",
      AddInspiration: {
        title: "Dodaj inspirację",
        subTitle: "Uzupełnij fomularz",
        subTitleStrong: "wszystkie pola są obowiązkowe.",
        firstInputDesc: "Załaduj zdjęcie stylizacji (maksymalnie 5):",
        firstInputHint:
          "Akceptowane formaty: jpg, png, gif; maksymalny rozmiar każdego zdjęcia: 10MB i 2000x2000;",
        secInputDesc: "Nadaj tytuł stylizacji:",
        thirdInputDesc:
          "Podaj, jakich produktów użyto do stworzenia stylizacji:",
        fourthInputDesc: "Imię i nazwisko",
        fifthInputDesc: "POS autora stylizacji",
        charInputHintP1: "Pozostało:",
        charInputHintP2: "znaków",
        addInspiration: "Dodaj stylizacje",
        addImg: "Dodaj zdjęcie",
        assuranceStringStart: "Oświadczam, że zapoznałem i zgadzam się z",
        regulations: "Regulaminem Dodawania Stylizacji",
        policy: "Polityką Prywatności",
        credentialsInfo:
          "Twoje imię i nazwisko będzie widoczne dla wszystkich wyświetlających tę pracę",
        errorMsg: "Pole wymagane*",
        imgLimit:
          "Osiągnięto limit zdjęć - usuń któreś, jeśli chcesz dodać inne",
        imgOversized: "Jedno lub więcej z wybranych zdjęć jest za duże!",
        imgSizeInfoP1:
          "Przed wrzuceniem zdjęcia zalecamy skorzystanie ze stron służących do skompresowania zdjęcia.",
        imgSizeInfoP2:
          "Skorzystaj z darmowych narzędzi do zmniejszania wagi np.",
        fileName: "Nazwa pliku:",
        fileSize: "Rozmiar:",
        addError:
          "Wystąpił problem podczas dodawania inspiracji, spróbuj ponownie.",
        editTitle: "Edytuj inspirację",
      },
      inspirationCard: {
        readMore: "Czytaj więcej",
        usedProducts: "Użyte produkty",
        author: "Autor",
        pos: "POS",
        dates: {
          minAgo: "min. temu",
          hourAgo: "godz. temu",
          dayAgo: "dzień temu",
          daysAgo: "dni temu",
        },
      },
      thankYouPage: {
        title: "Dziękujemy, że dzielisz się pięknem!",
        subTitleP1:
          "Twoja inspiracja została przesłana do weryfikacji i oczekuje na akceptację. Po zaakceptowaniu pojawi się na",
        subTitleP3:
          "Wracaj regularnie, ay obserwować, jak Twoja praca podoba się innym konsultantkom! :)",
        mainPageLink: "Stronie Głównej SOK",
        linksConnect: "oraz w",
        allPagesLink: "spisie wszystkich inspiracji",
        backBtn: "powrót do inspiracji",
        buttonText: "Powrót do inspiracji",
      },
    },
    Admin: {
      title: "Panel administratora",
      subtitle:
        "Zatwierdzaj inspiracje, dodawaj i edytuj profile użytkowników.",
      userTitle: "Zarządzaj użytkownikami",
      addUser: "Dodaj użytkownika",
      addNewUser: "Dodaj nowego użytkownika",
      editUser: "Edytuj użytkownika",
      editUserSubtitle:
        "Wprowadź zmiany a następnie zatwierdź. Jeśli chcesz zmienić hasło, kliknij przycisk 'Zmień hasło', wprowadź zmianę a następnie potwierdź za pomocą przycisku 'Edytuj użytkownika'.",
      save: "zapisz",
      goEdit: "Przejdź do edycji",
      inspirationTitle: "Zatwierdzaj inspiracje.",
      inspirationTitlev2:
        "Aby to zrobić, przejdź do konkretnej inspiracji, na samym dole znajdziesz przyciski do zaakceptowania lub odrzucenia.",
      inspirationTitlev3:
        "W przypadku odrzucenia, pojawi się pole tekstowe aby wpisac uwagi. Po wypełnieniu kliknij przycisk ponownie.",
      approve: "Zatwierdź",
      reject: "Odrzuć",
      confirmReject: "Wyślij",
      fillFields: "Uzupełnij wszystkie puste pola",
      editError: "Dane nie zostały edytowane",
      userAdded: "Użytkownik dodany",
      editInfo: "Log in and go to edit to change user data.",
      passRepeatError: "Hasła nie są jednakowe",
      addUserError:
        "Upewnij się, że uzupełniłeś wszystkie pola i spróbuj pownownie",
      addUserSuccess: "Użykownik dodany pomyślnie.",
      nameError:
        "Imię zawiera niedozwolone znaki lub jest krótsze niż 3 litery.",
      surnameError:
        "Nazwisko zawiera niedozwolone znaki lub jest krótsze niż 3 litery.",
      emailError: "Email powinien wyglądac w następujący sposób x@y.z",
      phoneError: "Numer powinien zawierać 9 cyfr.",
      approveError: "Podaj wiadomość, jesli chcesz odrzucić",
      emailRepeatedError: "Email jest już zajęty",
      addUserTitle:
        "Dodaj użytkownika. Pamiętaj aby uzupełnić wszystkie pola tekstowe.",
      editUserTitle: "Wyszukuj i edytuj użytkowników.",
      editUserSuccess: "Użykownik edytowany pomyślnie.",
      changePassword: "Zmień hasło",
      placeholders: {
        name: "Imię",
        surname: "Nazwisko",
        email: "Email",
        phone: "Numer telefonu",
        password: "Hasło",
        repeatPassword: "Powtórz hasło",
        newPassword: "Nowe hasło",
        pos: "Pos",
        admin: "Prawa administratora",
        active: "Aktywuj użykownika",
        rejected: "Wprowadź powód odrzucenia i kliknij ponownie.",
        search: "Szukaj: imię, nazwisko, email",
        edit: "Edytuj",
      },
    },
    MainPage: {
      subtitle: "Kompedium wiedzy dla Konsultantek NEONAIL.",
      subtitleV2:
        "Rozwiajaj się, zarabiaj i zdobywaj unikalne doświadczenia z COSMO GROUP!",
      activeService: "Aktywna obsługa klienta",
      consultantLook: "Wygląd konsultantki",
      islandLook: "Wygląd wyspy",
      seeMore: "zobacz więcej",
      maniQuestion: "Stworzyłaś ciekawe mani?",
      incentive: "Pochwal się swoją stylizacją!",
    },
    ActiveService: {
      title: "Aktywna obsługa klienta",
      sectionTitleA: "Dlaczego Standardy Obsługi Klienta?",
      sectionTitleB: "Baw się sprzedażą!",
      sectionContentA:
        "Podążając za zmieniającymi się trendami na świecie stworzyliśmy ulepszony SOK (Standardy Obsługi Klienta). Jak na pewno zauważyliście – Klienci nie są zainteresowani sztucznie brzmiącymi formułami i formalną obsługą.",
      sectionContentB:
        "Na podstawie szerokich badań, które COSMO GROUP zrealizowało na swoich Klientach w tym roku wychodzimy naprzeciw sygnałom odwiedzających nas Klientów o potrzebie zmiany i aktualizacji dotychczasowych Standardów Obsługi Klienta.",
      sectionContentC:
        "Dzięki takiej modyfikacji wyróżnimy się na tle konkurencji. Chcemy również umożliwić Wam wykorzystanie własnego potencjalu sprzedażowego a co za tym idzie rozwijać Wasze umiejętności.",
      readMore: "Czytaj dalej",
      prevPost: "Poprzedni wpis",
      consultant: "Wygląd konsultantki",
    },
    Consultant: {
      title: "Wygląd konsultantki",
      sectionTitle:
        "Wygląd Konsultantki to nieodłączny element pracy w branży beauty.",
      sectionTitleA: "Pierwsze wrażenie",
      sectionTitleB: "Do której konsultantki masz ochotę podejść?",
      sectionContentA: "Twój look to wizytówka COSMO GROUP.",
      sectionContentA1:
        "Dla Twojej Klientki jesteś wzorem i autorytetem do naśladowania. Dlaczego? Bo Twoja Klientka postrzega Cię jako ambasadora firmy m.in. ambasadora marki NEONAIL, NEO MAKEUP oraz NEO CARE.",
      sectionContentB:
        "Jako firma każdego dnia podążamy za najnowszymi trendami i chcemy aby #COSMOTEAM również był en vogue. Wychodząc naprzeciw wymagającej branży zapewniamy Wam nowości, które otrzymuje każdy zespół POS od firmy m.in. kolekcje lakierów hybrydowych oraz premierowe produkty do makijażu.",
      sectionContentC:
        "Najczęściej sprzedawany lakier hybrydowy? Ten, który masz na paznokciach! Najczęściej sprzedawany odcień pomadki? Ten, który masz na ustach! Nowości, które otrzymuje każda Konsultantka od firmy to nie tylko najlepsza forma reklamy, ale najlepszy i najskuteczniejszy mechanizm sprzedażowy. Ile razy udało Ci się zbudować duży paragon z tych produktów, z których masz wykonany makijaż lub stylizację manicure? Właśnie dlatego wszystkie nowe produkty, które otrzymujecie w dniach premiery powinnyśmy prezentować na sobie – zagwarantuje to nam wysoką sprzedaż.",
      moduleTitle: "Wygląd sprzedawcy",
      listTitle: "Niezbędne atrybuty sprzedawcy NEONAIL",
      listElementA: "Subtelny makijaż-delikatne oko, mocne usta",
      listElementB: "Schludna fryzura",
      listElementC: "Fartuszek NEONAIL",
      listElementD: "Identyfikator",
      listElementE: "Uśmiech na twarzy!",
      sectionContentD:
        "Nie zapominajmy o pielęgnacji. To kluczowy element naszego dobrego samopoczucia. Zadbajmy o siebie również od wewnątrz. Zdrowa dieta, aktywność fizyczna to podstawa promiennego wyglądu.",
      sectionContentF: "Piekno jest tym co znajduję się wewnątrz nas...",
    },
    Island: {
      title: "Wygląd wyspy",
      sectionTitle: "Czas na nowe mani!",
      sectionTitleA: "Pierwsze wrażenie",
      sectionTitleB: "Do której konsultantki masz ochotę podejść?",
      sectionContentA1:
        "Jak myślisz co skłania Klienta do podejścia na wyspę? Kluczowym elementem jest pierwsze wrażenie. Gdy jesteśmy w galerii nasze wyspy są zlokalizowane na środku,",
      sectionContentB1:
        "co sprawia, że jesteśmy na widoku. Podczas zakupów chętniej podejdziemy do Konsultantki, która jest otwarta i profesjonalna niż do miejsca gdzie osoba nie jest zainteresowana Klientem tylko sprawami nie związanymi z pracą. Czy wiecie co to jest efekt Aureoli ? Nie ? Już spieszymy z wyjaśnieniami.",
      sectionContentA:
        "Wybierasz się do galerii, widzisz z daleka stoisko, w którym chcesz zaopatrzyć się w produkty do nowego manicure.. podchodzisz i co widzisz?",
      sectionContentB:
        "Dlaczego chcesz dokonać zakupu właśnie w tym miejscu? Co przekonuje Cię i zachęca do podejścia do stoiska?",
      sectionContentC: "Zastanów się...",
      sectionContentD: "Zwracasz uwagę na czystość?",
      sectionContentE:
        "Na dobrze zatowarowane stanowisko i ładnie wyeksponowane produkty? Jasne, że TAK! Więc...",
      moduleTitle: "Checklista ładnej wyspy",
      moduleTitleA: "Efekt aureoli",
      listTitle: "Zawsze pamiętaj, aby:",
      listTitleA: "Efekt aureoli składa się z:",
      listDescA:
        "Efekt Aureoli w psychologicznej definicji jest to tendencja do automatycznego przypisania cech osobowości",
      listDescB:
        "Co to oznacza dla nas ? Tak jak Klient zobaczy nas po raz pierwszy, decyduje o tym czy zrobi zakupy czy nie. Dlaczego ? Ponieważ pierwsze wrażenie robi się tylko raz.",
      listElementA1: "Stoisko i jego otoczenie było ",
      listElementA2: "czyste",
      listElementB1: "Asortyment był weksponowany ",
      listElementB2: "zgodnie ze standardami VM",
      listElementC1: "Produkty były ustawione estetycznie ",
      listElementC2: "i z właściwą cenówką",
      listElementD1: "Poziom zatowarowania ",
      listElementD2: "ekspozycji był prawidłowy",
      listElementE1: "Oferta promocyjna i nowości ",
      listElementE2: "były wyeksponowane zgodnie z VM",
      listElementF1: "Materiały reklamowe ",
      listElementF2: "były uporządkowane i dostępne dla Klienta",
      listElementG1: "Wzorniki ",
      listElementG2:
        "były aktualne, zgodne z ofertą Twojego POS oraz kompletne i czyste",
      listElementH1: "Przy kasie ",
      listElementH2: "dostępna była informacja o zwrotach",
      listElementI1: "Stan techniczny ",
      listElementI2: "wyspy nie wzbudzał wątpliwości",
      listElementJ1: "Testery",
      listElementJ2:
        "były dostępne i czyste czyli zachęcające do użycia – testerem sprzedajemy poprzez zapach, konsystencję, nasycenie, wchłanianie. Od ich wyglądu zależy czy Klient będzie chciał skorzystać z tego produktu i go spróbować.",
      secondListElementA: "Mowy ciała i kontaktu wzrokowego",
      secondListElementB: "Ubioru",
      secondListElementC: "Pewności siebie",
      secondListElementD: "Głosu",
      secondListElementE: "Mimiki",
      secondListElementF: "Zapachu",
    },
    DifficultSituations: {
      title: "Trudne sytuacje - filmy instruktażowe",
      listElementA: "Klientka przychodzi ze zdjęciem lakieru innej marki",
      listElementB:
        "Obiekcja klienta: lampy innych marek o tych samych parametrach są tańsze",
      listElementC: "Klientka mówi: „ja tylko oglądam”",
      listElementD:
        "Obiekcja klienta: produkty waszej marki są tańsze w innej drogerii",
      listElementE: "Klientka rezygnuje z zakupów",
      listElementF: "Klientka korzysta z produktów innej marki",
      listElementG:
        "Klientki uważają, że moc frezarki jest za słaba, bo oglądają tutoriale na YT",
      listElementH: "Porównywanie cen do innych marek",
      listElementI: "Zwrot towaru",
      listElementJ: "Obsługa dwóch klientek na raz",
      listElementK: "Odrzucona reklamacja",
      listElementL: "Klientka, która się śpieszy",
      listElementM:
        "Klientka, która w trakcie oglądania wzorników rozmawia przez telefon",
    },
    Relations: {
      title: "Powitanie, zaufanie i relacje",
      sectionTitle: "Spotykasz nową osobę...",
      sectionContentA:
        "i już po kilku chwilach wiesz, czy ją polubisz, czy z chęcią zrobisz u niej zakupy i jak duże. Przypominasz sobie swoje ostatnie wyjście na shopping?",
      sectionContentB:
        "Każdy z nas prawdopodobnie doświadczył odczucia, kiedy „od pierwszego spojrzenia” czuł sympatię lub niechęć do całkiem obcej osoby. Dlaczego tak się dzieje? W czym tkwi sekret?",
      sectionContentC:
        "Tak naprawdę powitanie to najlepsza szansa na nawiązanie kontaktu z Klientem. A To tylko pierwsze kilka sekund!",
      moduleTitle: "Jak jesteśmy postrzegani",
      listTitle: "Co wpływa na to jak jesteśmy postrzegani?",
      listElementA: "55 % język ciała",
      listElementB: "38 % ton głosu",
      listElementC: "7 % słowa",
      sectionContentD:
        "Spójrz raz jeszcze na powyższe dane. Słowa tylko 7%? TAK! To język ciała, wyraz twarzy, UŚMIECH, kontakt wzrokowy, pewność siebie, swoboda są głównym źródłami informacji przy pierwszym kontakcie z Klientem.",
      sectionContentE:
        "A gdyby Twoją Klientką była Twoja mama, siostra, ciocia, najlepsza przyjaciółka - jak wyglądałaby Twoja reakcja na takie Klientki? Jak zachowałbyś się podczas takiej obsługi?",
      sectionContentF:
        "A gdyby tak, obsłużyć każdego Klienta w taki sposób o jakim pomyślałaś? Bingo! Masz to!",
      sectionContentG:
        "Gesty, ruchy ciała, tempo, rytm mówienia – podczas rozmowy z Klientem wzmacnia przekaz",
      sectionContentH:
        "Uśmiech - bardzo ważny podczas całej obsługi Klienta - zachęca do rozmowy, rozładowuje ewentualne napięcie, rozwesela, mobilizuje i angażuje.",
      moduleTitleA: "Pamiętaj!",
      listTitleA: '"Twarz powie szybciej, niż pomyśli głowa"',
      secondListElementA:
        "To właśnie mimika stanowi najbardziej wymowny sposób komunikacji niewerbalnej. Sposób, w jaki mówimy, kontakt wzrokowy odkrywa Twój stosunek do Klienta. Nie unikaj go!",
      sectionTitleA: "Powitanie",
      sectionContentI:
        "Wiecie już jak ważne jest pierwsze wrażenie oraz zbudowanie relacji. Jak myślicie - czy jest różnica czy przywitacie Klienta czy też nie ? Oczywiście że jest. Tak samo jak w tym czy rozpoznacie i prawidłowo przywitacie stałego Klienta. Na pewno będzie jej o wiele milej jak spersonalizujesz przywitanie specjalnie dla niej.",
      moduleTitleB: "Przykłady powitań",
      thirdListElementA: "Dzień dobry",
      thirdListElementB: "Dzień dobry miło mi Panią widzieć ponownie",
      thirdListElementC:
        "Dzień dobry jakiego produktu tym razem Pani poszukuje ?",
    },
    Needs: {
      title: "Badanie potrzeb klienta",
      sectionTitle: "Czym jest badanie potrzeb?",
      sectionContentA:
        "Mówiąc krótko - to zbieranie informacji o Kliencie, a konkretnie o tym czego pragnie, jakie są jego problemy i oczekiwania. To niezbędny element rozmowy handlowej, który pozwala nam sprzedać więcej. Dzięki skutecznemu badaniu potrzeb, jesteśmy w stanie zaproponować Klientowi idealny produkt dopasowany do jego oczekiwań.",
      sectionContentB:
        "Odpowiednio zadane pytania otwierają nam drzwi do sprzedaży wielu produktów.",
      sectionContentC:
        "Ważna jest chęć realnej pomocy Klientowi i szczerość w relacjach. Pamiętajmy, aby podtrzymywać rozmowę z klientem w spokojnej atmosferze zrozumienia. Klient powinien czuć się zaopiekowany.",
      sectionContentD:
        "Najprostszą metodą badania potrzeb jest ciekawość drugiego człowieka. Nie skupianie się na tym jakie pytania zadać, tylko co chcemy wiedzieć o danej osobie. Dlaczego ona właśnie dziś trafiła na naszą wyspę. Zainteresowanie sprzedawcy bardzo dobrze działa na Klientów, powoduje zwiększenie ich zaufania i otwiera ich na rozmowę z Konsultantką.",
      moduleTitle: "Przykład",
      listTitle: "Przykładowe badania potrzeb na wyspie NEONAIL",
      listElementA:
        "Jakich produktów Pani używała do tej pory, co się sprawdziło?",
      listElementB:
        "Który etap wykonywania manicure jest dla Pani najtrudniejszy?",
      listElementC: "Czy robi Pani sama stylizację? Jak długo?",
      listElementD: "Jakiego efektu Pani oczekuje?",
    },
    ClientTypes: {
      title: "Typy klientek",
      moduleTitle: "Czego Young Authentic oczekuje odwiedzając wyspę NEONAIL?",
      moduleTitleA: "Czego Nude Mummy oczekuje odwiedzając wyspę NEONAIL?",
      moduleTitleB: "Czego Feminine Woman oczekuje odwiedzając wyspę NEONAIL?",
      sectionTitleA:
        "Young Authentic: singielka, żyje tu i teraz, chce być w trendzie.",
      sectionTitleB:
        "Nude Mummy: ma małe dzieci, mistrzyni planowania, stawia na wygodę",
      sectionTitleC:
        "Feminine Woman: pracuje, jest zdecydowana, ma własny styl",
      sectionTitleD:
        "KLUCZOWE WARTOŚCI, KTÓRYMI KIERUJĄ SIĘ KLIECI WYBIERAJĄC MARKĘ NEONAIL:",
      sectionContentA:
        "Zgodnie z przeprowadzonymi badaniami zostały zdefiniowane trzy kluczowe typy Klientek COSMO GROUP. W oparciu o analizę stylu życia, etapu życia oraz pełnionej roli każda z tych trzech typów ma inne potrzeby zakupowe. Czy wiesz co wyróżnia? ",
      sectionContentB:
        "Jej największą inspiracja jest IG, YouTube, TikTok. Interesuje się trendami i influencerami - stamtąd czerpie inspiracje. Lubi mieć to co modne i popularne wśród rówieśników.",
      sectionContentC:
        "Jej największą inspiracją jest IG, Facebook. Grupy na FB to główne kanały, z których czerpie inspirację i wiedzę o nowościach, markach, produktach. Lubi mieć coś nowego, markowego, jako forma nagradzania siebie i budowania wizerunku, ale nie musi to być nowość i oryginał. Kiedyś robienie mani było dla niej przyjemnością, teraz trudno jej wygenerować 1,5h dla siebie, dlatego szuka rozwiązań, tipów, które skrócą czas zrobienia mani.",
      sectionContentD:
        "Robienie mani to dla niej przyjemność, czas tylko dla siebie. Lubi podkreślać swoją kobiecość. W stylizacjach nieco zachowawcza i klasyczna, woli eksperymentować z kolorem paznokci niż stylizacji. Jej inspiracją również są grupy na Facebook. Lubi mieć coś nowego. Jest to forma nagradzania siebie i budowania wizerunku, ale nie musi to być w trendzie, musi się jej podobać, pasować do niej. Nie śledzi regularnie promocji, ale chętnie z nich korzysta jeśli jest okazja.",
      listElementD: "Szybkiego i trafnego dobrania produktu  ",
      listElementA: "Szybkiego i trafnego dobrania produktu  ",
      listElementB:
        "Inspiracji – to właśnie Ty możesz być jej natchnieniem do zakupów",
      listElementC:
        "Rekomendacji i znajomości trendów w stylizacji, szczególnie promowanych przez influencerów np.: Glazed Donuts w stylu Hailey Bieber ",
      listElementE:
        "Tipów i rekomendacji jak zaoszczędzić czas robienia mani oraz przedłużyć ich trwałość ",
      listElementF:
        "Eksperckiego doradztwa - Twoja wiedza produktowa i doświadczenie w mani jest kluczem do sukcesu",
      listElementG: "Profesjonalnej obsługi ",
      listElementH: "Dobrej organizacji procesu zakupu ",
      listElementI: "Zakupy rozpoczyna od listy potrzebnych rzeczy, ",
      listElementJ:
        "Kocha nowości, nie potrafi sobie odmówić i kupuje to co jej się dodatkowo spodoba ",
      listElementK1: "NEONAIL buduje wizerunek kobiety i jej pewność siebie",
      listElementK2:
        "Dłonie stają się wizytówką kobiety. Dzięki mani kobieta czuje się zadbana i atrakcyjna, podoba się sobie. ",
      listElementL1: "NEONAIL to inwestycja w siebie",
      listElementL2:
        "Podejmując decyzję o wyborze marki chce mieć pewność co do jakości produktów, które będą proste i wygodne w aplikacji oraz trwałe. Stawia na markowe produkty. Kieruje się opiniami, kupuje produkty polecane przez najbliższych, stylistki lub influencerów. Strona www, e-sklep, punkt sprzedaży POS oraz komunikacja prowadzana przez marki budują wiarygodność i zaufanie, są potwierdzeniem jakości.",
      listElementM1: "NEONAIL łączy pokolenia kobiet",
      listElementM2:
        "Kuferek i kosmetyczka mamy to często początek przygody córki z mani i makijażem. Dzieci podpatrują i naśladują swoje mamy, malowanie paznokci staje się domowym „kobiecym” rytuałem i pretekstem na wspólne spędzanie czasu. Mamy inspirują i zarażają pasją do mani córki, to one wprowadzają je w świat marek, które cenią i stosują. To również babci lub teściowe, dla których zadbane dłonie to podstawa kobiecość.",
    },
    ActiveListening: {
      title: "Aktywne słuchanie",
      sectionTitle:
        "Sztuka słuchania w sprzedaży nie jest taka prosta. Istnieje spora różnica w SŁUCHANIU a SŁYSZENIU.",
      sectionContentA:
        "Proste metody, które mogą pomóc nam w zrozumieniu Klienta oraz identyfikacji jego potrzeb:",
      sectionContentC:
        "Brak aktywnego słuchania wpływa na ograniczenie zaufania Klienta do sprzedawcy.",
      sectionContentD:
        "Najważniejszą techniką aktywnego słuchania jest uważność i zrozumienie. Jeżeli dokładnie wysłuchamy z czym Klient się boryka, jakie ma potrzeby, będziemy w stanie dopasować do jego oczekiwań szereg produktów.",
      sectionContentE:
        "Gdy zbadałaś potrzeby Klienta oraz wiesz jakiego produktu poszukuje i masz w ofercie kilka podobnych produktów zaproponuj mu wybór jednego produktu z maksymalnie trzech alternatyw. Przykład: Kupujący przychodzi z potrzebą nowego czerwonego lakieru. Podając mu cały wzornik  składający się z ok. 30 odcieni czerwieni, zostawiamy go z bardzo trudnym wyborem. Często sam nie potrafi się zdecydować. Naszą rolą jest wskazanie najlepiej trzech kolorów/propozycji które definiują jego potrzeby. W sytuacji, gdy Klient nie może zdecydować się nawet na jedną z trzech propozycji, warto przejąć inicjatywę wyboru i zdecydowanie wskazać Klientowi kolor/produkt np. „Mi osobiście najbardziej odpowiada ten kolor”, „Ten kolor najlepiej sprzedawał się w zeszłym miesiącu”, „Do Pani karnacji idealnie pasuje ten odcień podkładu”, „Ten kolor idealnie współgrać będzie z tym kolorem, który już Pani wybrała”.",
      moduleTitleA: "Parafraza",
      listTitleA:
        "Jest to powtórzenie słów Klienta własnymi słowami aby upewnić się czy dobrze go zrozumieliśmy np.:",
      listElementA1: "Jeżeli dobrze Panią zrozumiałam to…",
      listElementA2: "Twierdzi Pani, że..",
      listElementA3: "Ok, czy dobrze rozumiem, że…",
      moduleTitleB: "Pytania precyzujące",
      listTitleB:
        "To zadawanie pytań, które doprecyzują to co Klient nam chce powiedzieć. To nie pozostawianie przestrzeni na niedopowiedzenia lub pomyłki np.:",
      listElementB3: "Jaki konkretnie produkt Pani się nie sprawdził?",
      listElementB1: "W jaki dokładnie sposób wykonuje Pani stylizację",
      listElementB2: "Dokładnie jaki produkt nie spełnił Pani oczekiwań",
      moduleTitleC: "Klaryfikacja",
      listTitleC:
        "To prośba o wyjaśnienie kwestii dla nas nie zrozumiałych. Kiedy nie masz pewności, że dobrze zrozumiałeś Klienta poproś o wyjaśnienie:",
      listElementC1: "Czy może Pani jeszcze raz wyjaśnić?",
      listElementC2: "Co skłoniło Panią do takiego spojrzenia?",
      listElementC3: "Ok, czy dobrze rozumiem, że…",
    },
    Advantages: {
      title: "Cecha, zaleta, korzyść",
      sectionTitle:
        "Sztuka słuchania w sprzedaży nie jest taka prosta. Istnieje spora różnica w SŁYSZENIU a SŁUCHANIU.",
      sectionContentA:
        "Jak myślicie? Co kupuje Klient przychodząc do nas na wyspę?",
      sectionContentB: "Produkt? Nie! Klient kupuje KORZYŚĆ!",
      sectionContentC:
        "Najczęstszym popełnianym błędem przez sprzedawców jest przedstawianie Klientowi cech oraz zalet, a to dopiero korzyść jest wymiernym plusem dla Klienta, który przekonuje go do zakupu.",
      sectionContentD:
        "Język korzyści tak opisuje produkt, aby stał się on odpowiedzią na wewnętrzne potrzeby Klienta, a czasami nawet te potrzeby kreował.",
      sectionContentE:
        "Warto zapamiętać, że klient nigdy nie kupuje samego produktu, tylko korzyści z nim związane np. ekonomiczne (oszczędność pieniędzy), funkcjonalne (oszczędność czasu), psychologiczne (efekt o jakim marzy).",
      sectionContentF:
        "Czy sprzedaż językiem korzyści jest łatwa? Nie. To proces, którego musimy się nauczyć po przez kontrolę prowadzenia rozmowy handlowej. Pamiętajmy, że nie jesteśmy w stanie przedstawić Klientowi korzyści zakupu, jeżeli nie zbadaliśmy jego potrzeb. Tylko odpowiednio zadane pytania, nakierują Nas na dobranie odpowiedniego produktu dla Klienta i przedstawienie plusów z jego zakupu.",
      moduleTitleA: "Cecha",
      listTitleA1: "NN - Lakier Simple ma w sobie bazę top i kolor",
      listTitleA2:
        "NMU - Nasz rozświetlacz posiada piękny połysk w postaci błyszczącej tafli. Produkt można stosować zarówno do ciała i twarzy",
      moduleTitleB: "Zaleta",
      listTitleB1:
        "NN - Dzięki lakierowi 3w1 oszczędza pani czas wykonując stylizacje",
      listTitleB2:
        "NMU - Dzięki swojej formule jest uniwersalny zarówno do ciała jak i twarzy oraz można go użyć jako cień, rozświetlacz czy dodać do balsamu",
      moduleTitleC: "Korzyść",
      listTitleC1: "NN - Ma pani piękny modny manicure w zaledwie 20 min",
      listTitleC2:
        "NMU - Nawet przy gorszej jesiennej pogodzie będzie Pani miała rozświetloną piękną skórę wyglądająca jak muśnięta słońcem",
    },
    Promotions: {
      title: "Promocje",
      sectionTitle:
        "Jakie pytanie zadaje najczęściej Klient podczas pierwszych minut na wyspie?",
      sectionContentA: "Na co same zwracacie uwagę idąc do galerii?",
      sectionContentB:
        "Oczywiście, że chodzi tutaj o promocje - jest to kluczowy moment naszej sprzedaży. Dobrze wiemy, że jest to świetne narzędzie do zwiększania koszyka sprzedażowego oraz do zachęcenia Klientów niezdecydowanych do zakupu.",
      sectionContentC:
        "Oprócz promocji w stałej ofercie mamy również specjalne mechanizmy promocyjne!",
      sectionContentD: "Co daje największą satysfakcję po shoppingu?",
      sectionContentE:
        "Endorfiny spowodowane udanymi zakupami oraz satysfakcja z zaoszczędzenia pieniędzy. Pamiętajcie, że nie ma mocniej działających słów na Klienta niż PROMOCJA ORAZ GRATIS ;) Narzędzia, którymi dysponujecie ułatwiają rozmowę z Klientem i dają większą szansę na finalizację takiej transakcji, jak również satysfakcję Klienta.",
      sectionContentF:
        "Pamiętaj aby każdy Klient został poinformowany o promocji, ofercie specjalnej.",
      moduleTitleA: "Przykład",
      listTitleA:
        "Pamiętajcie o tym, że to narzędzie stosujemy tak, aby Klient poczuł różnice pomiędzy zakupem w promocyjnej cenie a cenie regularnej np.:",
      listElementA1:
        "Mamy teraz promocję 3+2 oznacza to, że przy zakupie 3 sztuk lakierów dwa kolejne otrzymuje Pani po 1 zł oszczędza Pani około 70 zł",
      listElementA2:
        "W tym momencie mamy świetną promocję 3+2 kupuje Pani 5 lakierów, a płaci Pani za 3. Wliczają się w to topy i bazy.",
      moduleTitleB: "Oferta specjalna",
      listTitleB:
        "Oferta specjalna ma na celu docenienie stałych Klientów, sprawienie aby poczuli się wyjątkowo i z przyjemnością do Nas wracali.",
      listElementB1:
        "Mechanizm „Więcej kupujesz, lakiery zyskujesz” - promocja dla osób, które chcą dokonać większych zakupów",
      listElementB2:
        "Mechanizm „Krótka data” - dodajemy lakiery lub przecenione produkty z krótką datą do zakupów Klienta, lub wykorzystując te lakiery i produkty do zwiększenia wartości zakupu Klienta",
    },
    Objections: {
      title: "Obiekcje Klienta",
      sectionTitle: "Jak ZBIĆ obiekcje?",
      sectionTitleA: "JAKIE SĄ NAJCZĘSTSZE OBIEKCJE KLIENTÓW?",
      sectionContentA:
        "Właśnie przedstawione zostały przez Ciebie korzyści Klientowi z produktu, omówione zostały wszystkie jego cechy i zalety…",
      sectionContentB:
        "Czekasz więc aż Klient zdecyduje się na produkt, powie sakramentalne TAK – biorę! Ale... nagle okazuje się, że psuje się idealny scenariusz, a Klient ma obiekcje .. czyli zastrzeżenia i wątpliwości do zakupu. Co wtedy? \n\n Wiesz dlaczego tak się dzieje?",
      sectionContentC:
        "Praktycznie w każdej transakcji prędzej czy później dochodzimy do etapu, w którym musimy zbić obiekcje Klienta. Mogą one pojawić się z wielu różnych powodów: Klienci są przepełnieni 'super ofertami', zbyt mało informacji przekazano o produkcie, czasem przez jedno słowo sprzedawcy, które zasieje ziarno wątpliwości. Kiedy Klient ma obiekcje do produktu pewnie wiele z Was sądzi że jesteście na przegranej pozycji i proces sprzedaży zakończy się rezygnacją z zakupu.",
      sectionContentD:
        "Jest zupełnie inaczej – to Twoja wielka szansa na sprzedaż! Obiekcje wskazują, że Klient jest zainteresowany ofertą i ją rozważa – inaczej nie zwróciłby uwagi na konkretne jej szczegóły.",
      sectionContentE: "Jakie wnioski nasuwają? ",
      sectionContentF:
        "Jeśli nie zadbasz o relacje, zbadanie potrzeb i zaproponowanie odpowiedniego produktu – Klient zarzuci Cię obiekcjami. Jeśli więc, na etapie zamykania transakcji pojawia się zbyt dużo obiekcji Klienta, oznacza to, że coś poszło nie tak już na samym początku.",
      sectionContentG:
        "Pamiętaj, że Klient angażuje się w proces sprzedaży od samego początku, dlatego im więcej poświęcisz uwagi Klientowi tym większa szansa, że obiekcji nie będzie – a tobie będzie łatwiej dopasować ofertę „szytą na miarę” dla każdego Klienta.",
      moduleTitleA: "Jakie są najczęstsze obiekcje Klienta?",
      listElementA1: "To jest za drogie...",
      listElementA2: "Muszę to jeszce przemyśleć...",
      listElementA3: "Nie wiem, czy to się u mnie sprawdzi...",
      listElementA4: "Używam innego produktu...",
      listElementA5: "Pięc kolorów to za dużo, poczekam na inną promocję",
      listElementB1P1: "To jest za drogie",
      listElementB1P2:
        "oczywiście, rozumiem, że produkt może wydawać się dla Pani drogi, jednak przeliczając pojemność oraz czas użytkowania i efekt uważam, że wyjdzie Pani korzystnie na tej transakcji",
      listElementB2P1: "Muszę to jeszcze przemyśleć",
      listElementB2P2:
        "rozumiem jednak pozwoli Pani, że rozwieję wątpliwości. Co powoduje u Pani te wątpliwości?",
      listElementB3P1: "Nie wiem, czy to się u mnie sprawdzi ",
      listElementB3P2:
        "produkt sprawdził się zarówno u mnie, ponieważ sama od lat go używam, oraz u innych Klientek, które wracają zadowolone. Stosując go zgodnie z przeznaczeniem jestem pewna, że się Pani sprawdzi",
      listElementB4P1: "Używam innego produktu ",
      listElementB4P2:
        "świetnie, czyli ma Pani już doświadczenie i będzie mogła Pani porównać formuły. Nasz produkt okaże się na pewno wart uwagi.",
      listElementB5P1: "Pięć kolorów to za dużo, poczekam na inną promocję ",
      listElementB5P2:
        "lakierów nigdy za dużo, do tej promocji wliczają się również bazy i topy które na pewno Pani wykorzysta. Płaci Pani tylko za 3 lakiery a otrzymuje Pani 5. Proponuję wybrać jakiś szalony kolor, na który normalnie by się Pani nie zdecydowała Za 1zł warto!",
      listElementC1P1: "Wszystkie obiekcje przyjmuj ze spokojem ",
      listElementC1P2: "dokładnie wysłuchaj Klienta",
      listElementC2P1:
        "Doprecyzuj i upewnij się, że rozumiesz wątpliwości Klienta ",
      listElementC2P2:
        "że widzicie ten sam niebieski prostokąt, a nie rozmawiacie o czerwonym kole. DOPYTAJ – zagraj z Klientem w PING-PONGA, odbij „piłeczkę”  – „Czy może Pani doprecyzować co konkretnie jest za drogie?”, „Którą kwestię chciałaby Pani przemyśleć?” ,”Co nie sprawdziło się u Pani przy ostatnim…  maniucre/makeup/pedicure?”",
      listElementC3P1: "Pokaż Klientowi zrozumienie ",
      listElementC3P2: "okaż swoje zaangażowanie w dbaniu o interesy Klienta",
      listElementC4P1: "Argumentuj ",
      listElementC4P2:
        "używaj trafnych argumentów – pozwalają one przekonać do swoich racji, dodatkowo pokazuje rozmówcy, że go słuchałeś. To jest najważniejszy moment - w tej chwili możesz przekonać Klienta do zakupu.",
      listElementC5P1: "Finalizuj ",
      listElementC5P2:
        "Celem rozmów handlowych jest sprzedaż. Zawsze zmierzaj do finalizacji sprzedaży",
    },
    Product: {
      title: "Produkt komplementarny",
      sectionTitle:
        "Mamy to! Klient zdecydował się na produkt, który proponujesz.",
      moduleTitle: "przykład",
      listTitle:
        "Klientka chciałaby spróbować przedłużyć paznokcie produktem duo acrylgel.",
      listElement:
        "Wybrany został produkt, ale czy każdy Klient wie jak użyć produktu? Co jeszcze jest potrzebne? Mamy wiele możliwości do zaproponowania np. pędzelek, tipsy, formy, cleaner.",
      sectionContentA: "Co dalej?",
      sectionContentB:
        "Zapraszamy Klienta do kasy i finalizujemy sprzedaż? Jeszcze nie ten moment! Teraz zaczynamy pracę nad wartością paragonu Klienta.",
      sectionContentC:
        "Sprzedaż komplementarna to technika zwiększania wartości zakupu poprzez proponowanie produktów uzupełniających do głównego produktu wybranego przez Klienta.",
      sectionContentD:
        "To technika sprzedażowa, która zamiast narzucać Klientom zakup rozbudowuje koszyk Klienta.",
      sectionContentE:
        "Wielu Klientów podejmuje decyzje pod wpływem impulsu. Gdy sprzedawca zaproponuje im konkretny produkt, którego sami nie znaleźli w sklepie istnieje wysokie prawdopodobieństwo, że go kupią, ale ważne jest, aby rekomendacja produktu komplementarnego wynikała ze stwierdzonych realnych potrzeb Klienta.",
      sectionContentF:
        "Dlaczego produkty komplementarne są ważne dla Klienta ale i również dla Ciebie? Klient czuje się ważny, ponieważ marka dba o jego potrzeby, oferując mu dodatkowe produkty, które mogą mu się przydać. Wychodząc ze stoiska ma pewność, że posiada wszystkie produkty do wykonania danej stylizacji.",
      sectionContentG:
        "Sprzedaż komplementarna pobudza do dalszych zakupów. Przypomnij sobie Twoją ostatnią sprzedaż. Pamiętasz jak Klientka powiedziała „Dobrze, że przypomniała mi Pani o tym produkcie”",
      sectionContentH:
        "Polecenie produktów komplementarnych, jako dopełnienie do głównego produktu, to jeden",
      sectionContentI:
        "z głównych czynników wpływających na ostateczną wartość zakupów Klienta. I to jest ważne właśnie dla CIEBIE! Wyższa wartość paragonu!",
      sectionContentJ:
        "Paragon tylko na produkt podstawowy 39,99 zł. Paragon z produktem podstawowym i dwoma produktami komplementarnymi min. 79,99 zł",
      sectionContentK:
        "Widzisz różnicę? Jeden Klient może zostawić nawet 2x więcej – a to wszystko Twoja zasługa.",
      sectionContentL:
        "WYŻSZA WARTOŚĆ PARAGONU = WYŻSZA REALIZACJA PLANU = WYŻSZA TWOJA PREMIA",
    },
    SaleClose: {
      title: "Zamknięcie sprzedaży",
      sectionTitle: "Ostatnim etapem przy sprzedaży jest finalizacja.",
      sectionContentA: "Co to oznacza? ",
      sectionContentB:
        "To jest moment, w którym zapraszamy Klienta, który ma odpowiednio dobrany produkt wynikający ze zbadania potrzeb oraz produkty komplementarne do kasy. To idealny moment na zaproponowanie produktu dodatkowego.",
      sectionContentC:
        "Jaki to produkt? Ten, który stanowi około 20% kwoty całego paragonu i możemy dodać go do każdych zakupów.",
      sectionContentD: "Pamiętajcie o tym, że celem rozmowy jest sprzedaż.",
      sectionContentE:
        "Warto stosować na koniec zwroty, które upewnią Klientkę o słuszności wyboru. Myślę, że same lubicie słyszeć pochwały i komplementy tak samo jak nasze Klientki.",
      sectionContentF:
        "Każdemu Klientowi wydajemy paragon, dziękujemy za zakupy i żegnamy uśmiechem ;)",
      sectionContentG: "#baw się sprzedażą",
      moduleTitleA: "Przykładowe końcowe zwroty",
      listElementA1: "Będzie Pani zadowolona z tego koloru",
      listElementA2: "Przepiękny kolor Pani wybrała",
      listElementA3: "Jest to nasz kolor z bestselerów świetny wybór",
      listElementA4:
        "W trosce o interes Klienta nie zapominamy o aplikacji i reklamówce.",
      listElementA5:
        "Zasugerujcie Klientowi np. Czy potrzebuje Pani reklamówkę do zakupów, a może ma Pani, gdzie schować produkt?",
      moduleTitleB: "Przykład",
      listElementB1: "Do widzenia i zapraszamy ponownie",
      listElementB2: "Dziękuję i zapraszam ponownie",
    },
    ToughCases: {
      title: "Trudne sprawy",
      moduleTitleA: "Pamiętaj",
      listElementA1:
        "Klient ma prawo zareklamować każdy produkt jeśli posiada do niego dowód zakupu lub potwierdzenie płatności. ",
      listElementA2:
        "Nie kłóć się podczas przyjmowania takiej reklamacji. Najważniejsza jest wyrozumiałość oraz profesjonalizm.",
      sectionContentA:
        "Nie zawsze jest tak pięknie i kolorowo, ale czy to oznacza, że gdy jest trudniej trzeba się poddawać ?",
      sectionContentB:
        "Są chwile kiedy Klient nie przychodzi na wyspę zrobić zakupy, ale chce złożyć reklamację lub ma jakiś inny problem związany z produktem.",
      sectionContentC:
        "Takie właśnie trudne sprawy nazywamy Procesem około sprzedażowym.",
      sectionContentD:
        "Co to oznacza dla Ciebie. Jak zachować się w takiej sytuacji? Ważne, aby zachować spokój uśmiech na twarzy oraz dobrą energię. To jak Klient zapamięta Cię w takiej sytuacji zaważy na tym czy wróci na wyspę zrobić zakupy. Masz swój ulubiony produkt, sprzęt, ubranie i coś się z nim stało idziesz złożyć reklamację jaki masz nastrój ? Jesteś szczęśliwa i zadowolona ? Raczej nie ale jeśli osoba przyjmująca taką reklamacje jest dla Ciebie miła i uprzejma oraz wyrozumiała od razu czujesz większy spokój.",
      sectionContentE:
        "Tak samo zachowaj się w momencie gdy Ty przyjmujesz takie sprawy u siebie na wyspie.",
      sectionContentF: "A co ze zwrotem ?",
      sectionContentG:
        "Przecież na wyspie jest informacja o tym, że zwrotów nie przyjmujemy. Co w przypadku kiedy to ty się pomylisz i źle wydasz produkt ? Wtedy też nie wykonasz zwrotu ? Oczywiście wszystko zależne jest od sytuacji i konsultacji z Twoim RKS. Z każdej sytuacji jest wyjście, pomyłki się zdarzają-jesteśmy tylko ludźmi. W sytuacjach trudnych kluczowe jest zachowanie spokoju oraz uśmiech na twarzy, który potrafi załagodzić każdy konflikt. #baw się sprzedażą",
    },
    SuggestedPages: {
      title: "Zobacz również",
      serviceTextA: "Dlaczego Standardy Obsługi Klienta?",
      serviceTextB:
        "Podążając za zmieniającymi się trendami na świecie stworzyliśmy ulepszony SOK (Standardy Obsługi Klienta). Jak zapewne zauważyliście...",
      consultantTextA:
        "Wygląd konsultantki to nieodłączny element pracy w branży beauty.",
      consultantTextB:
        "Twój look to wizytówka Cosmo. Jako firma każdego dnia podążamy za najnowszymi trendami i chcemy aby #cosmoteam...",
      islandTextA: "Czas na nowe mani!",
      islandTextB:
        "Wybierasz się do galerii, widzisz z daleka stoisko, w którym chcesz zaopatrzyć się w produkty do nowego manicure.. podchodzisz i co widzisz? Dlaczego chcesz dokonać zakupu właśnie w tym miejscu?...",
      relationsTextA: "Spotkasz nową osobę...",
      relationsTextB:
        "już po kilku chwilach wiesz, czy ją polubisz, czy z chęcią zrobisz u niej zakupy i jak duże.  Przypominasz sobie swoje ostatnie wyjście na shopping? ...",
      needsTextA: "Czym jest badanie potrzeb?",
      needsTextB:
        "Mówiąc krótko - to zbieranie informacji o Kliencie, a konkretnie o tym czego pragnie, jakie są jego problemy i oczekiwania.",
      activeListeningTextA:
        "Sztuka słuchania w sprzedaży nie jest taka prosta. Istnieje spora różnica w SŁYSZENIU a SŁUCHANIU.",
      activeListeningTextB:
        "Proste metody, które mogą pomóc Nam w zrozumieniu Klienta oraz identyfikacji jego potrzeb...",
      advantagesTextA:
        "Jak myślicie? Co kupuje Klient przychodząc do Nas na wyspę? Produkt? Nie! Klient kupuje KORZYŚĆ!",
      advantagesTextB:
        "Najczęstszym popełnianym błędem przez sprzedawców jest przedstawianie Klientowi cech oraz zalet, a to dopiero korzyść jest wymiernym plusem dla Klienta, który przekonuje go do zakupu.",
      promotionsTextA:
        "Jakie pytanie zadaje najczęściej Klient podczas pierwszych minut na wyspie?",
      promotionsTextB:
        "Na co same zwracacie uwagę idąc do galerii? Oczywiście, że chodzi tutaj o promocje - jest to kluczowy moment naszej sprzedaży...",
      objectionsTextA:
        "Właśnie przedstawione zostały przez Ciebie korzyści Klientowi z produktu, omówione zostały wszystkie jego cechy i zalety… ",
      objectionsTextB:
        "zekasz więc aż Klient zdecyduje się na produkt, powie sakramentalne TAK – biorę! Ale... nagle okazuje się, że psuje się idealny scenariusz, a Klient ma obiekcje ...",
      productTextA:
        "Mamy to! Klient zdecydował się na produkt, który proponujesz.",
      productTextB:
        "Co dalej? Zapraszamy Klienta do kasy i finalizujemy sprzedaż? Jeszcze nie ten moment! Teraz zaczynamy pracę nad wartością paragonu Klienta...",
      saleCloseTextA: "Ostatnim etapem przy sprzedaży jest finalizacja.",
      saleCloseTextB:
        "Co to oznacza? To jest moment, w którym zapraszamy Klienta, który ma odpowiednio dobrany produkt wynikający z badania potrzeb oraz produkty komplementarne do kasy...",
      toughCasesTextA:
        "Nie zawsze jest tak pięknie i kolorowo, ale czy to oznacza, że gdy jest trudniej trzeba się poddawać ? ",
      toughCasesTextB:
        "Są chwile kiedy Klient nie przychodzi na wyspę zrobić zakupy, ale chce złożyć reklamację lub ma jakiś inny problem związany z produktem. Takie właśnie trudne sprawy nazywamy...",
      clientTypesTextA:
        "Zgodnie z przeprowadzonymi badaniami zostały zdefiniowane trzy kluczowe typy Klientek Cosmo",
      clientTypesTextB:
        "W oparciu o analizę stylu życia, etapu życia oraz pełnionej roli każda z tych trzech typów ma inne potrzeby zakupowe. Czy wiesz co wyróżnia? ",
      readAll: "Czytaj całość",
    },
    BlogContent: {
      title: "Wszystkie wpisy standardów obsługi klienta",
      sectionContentA: "Kompedium wiedzy dla Konsultantek NEONAIL.",
      sectionContentB:
        "Rozwijaj się, zarabiaj i zdobywaj unikalne doświadczenie z COSMO GROUP!",
    },
    CosmoTeam: {
      title: "#CosmoTeam",
      titleA: "Nasze wartości",
      titleB: "cosmo group w liczbach",
      sectionContentA:
        "to międzynarodowa firma kosmetyczna oferująca produkty do stylizacji i pielęgnacji paznokci, dłoni, stóp oraz twarzy.",
      sectionContentB:
        "Nasze kosmetyki trafiają do punktów dystrybucji rozsianych po całej Polsce, ale również za granicą m.in. do Niemiec, Rosji, Czech, Austrii, Anglii, RPA czy Grecji",
      sectionContentC: "Misja",
      sectionContentD:
        "Naszą misją jest tworzenie innowacyjnych kosmetyków oraz poszerzanie asortymentu zgodnie z najnowszymi światowymi trendami oraz potrzebami konsumentek.",
      sectionContentE:
        "Branża beauty to nasza pasja, dlatego każdego dnia inspirujemy, pokazujemy nowe rozwiązania i pomysły na kobiece piękno. Połączenie nowoczesnych technologii, innowacyjnych składników pozwoliło nam stworzyć szeroką gamę produktów odpowiednią dla wszystkich kobiet.",
      sectionContentE1:
        "Dbamy o partnerskie relacje z klientami, kontrahentami i pracownikami, którzy współtworzą sukces naszej firmy.",
      sectionContentF: "Ambicja",
      sectionContentG:
        "U podstaw wartości Cosmo Group leży zdrowa ambicja. Mamy odwagę i szukamy nowych wyzwań. Mamy apetyt na więcej, co napędza nas w działaniu i realizacji wyznaczonych celów. Cechuje nas wytrwałość w ich realizacji i potrzeba stałego rozwoju.",
      sectionContentG1: "Chcemy sięgać po więcej!",
      sectionContentH: "Pasja",
      sectionContentI:
        "Pasja to siła napędowa naszej organizacji, to nasze paliwo. Mamy odwagę i chęć wychodzenia poza schematy. Działamy z entuzjazmem, pełni energii i zaangażowania. Robimy to co kochamy i sprawia nam to radość!",
      sectionContentK: "Innowacyjnosć",
      sectionContentL:
        "Innowacyjność to duch organizacji Cosmo Group. Jesteśmy kreatywni, otwarci na zmiany i szukamy niestandardowych rozwiązań.",
      sectionContentM:
        "Wyznaczamy trendy i szukamy szans rynkowych. Dla nas „dobre” nie znaczy najlepsze!",
      sectionContentN: "Wiarygodność",
      sectionContentO:
        "Wiarygodność jest sercem naszej organizacji. Jesteśmy firmą godną zaufania i oferujemy naszym klientom bezpieczne produkty. Dbamy o środowisko zewnętrzne.",
      sectionContentP:
        "Odpowiedzialność, szczerość i rzetelność to standardy naszej pracy. Można na nas polegać!",
      sectionContentR: "Partnerstwo",
      sectionContentS:
        "Partnerstwo przejawia się w naszym codziennym działaniu. To szacunek, chęć niesienia pomocy i zaufanie.",
      sectionContentT:
        "Zdanie każdego pracownika naszej firmy jest tak samo ważne. Jesteśmy dla siebie wsparciem w codziennej podróży zawodowej!",
      iconListA: "W ciągu jednej sekundy",
      iconListA1: "sprzedajemy 4 produkty",
      iconListB: "Działamy na rynku",
      iconListB1: "już od 16 lat",
      iconListC: "Mamy 770 kontrahentów",
      iconListC1: "w 40 krajach na świecie",
      iconListD: "70% wzrostu sprzedaży",
      iconListD1: "w latach 2018-2021",
      iconListE: "Zatrudniamy",
      iconListE1: "1000 pracowników",
      iconListF: "3600 punktów sprzedaży",
      iconListF1: "na całym świecie",
      iconListG: "1,5 mln fanów",
      iconListG1: "w social mediach",
      firstSectionContentA: "Międzynarodowa firma z sektora beauty.",
      firstSectionContentB: "Lider sprzedaży",
      firstSectionContentC: "w kategorii lakierów hybrydowych.",
      missionSectionContentA: "Nasza misja",
      missionSectionContentB:
        "Dajemy siłę do wyrażania siebie w każdym momencie.",
      missionSectionContentC: "Incredible everyday YOU.",
      missionSectionContentD: "Nasza wizja",
      missionSectionContentE:
        "Najszybciej rozwijający się, działający globalnie",
      missionSectionContentF: "polski producent branży beauty.",
      brandsSectionContentA: "Nasze marki",
      brandsSectionContentB: "Poznaj marki",
      brandsSectionContentC: "Cosmo Group",
      mysterySectionContentA: "Branża beauty nie ma przed nami",
      mysterySectionContentB: "żadnych tajemnic",
      mysterySectionContentC:
        "W portfolio firmy znajdują się marki NEONAIL, MYLAQ, NÉO MAKE UP, NEONAIL Expert.",
      mysterySectionContentD:
        "Bezprecedensowo dynamiczny rozwój firma zawdzięcza strategii innowacyjności i najwyższej jakości produktów.",
      historySectionContentA: "Nasza historia",
      historySectionContentB: "Rodzinna firma",
      historySectionContentC: "o międzynarodowym zasięgu",
      historySectionContentD:
        "Jesteśmy rodzinną, międzynarodową firmą z polskim kapitałem, która stała się liderem branży beauty w sektorze stylizacji paznokci. Nasze marki podbijają rynek i serca klientów – można znaleźć je w ponad 1000 punktach sprzedaży w całej Polsce oraz w 40 krajach na całym świecie.",
      historySectionContentE:
        "  Poznajcie założycieli i Zarząd Cosmo Group. To oni stoją za sukcesem i rozwojem firmy. Kamila Błażejewska czuwa nad wizją oraz strategią wszystkich marek z portfolio spółki. Artur Błażejewski odpowiada za dział sprzedaży – jest strategiem, który szuka usprawnień operacyjnych i technologicznych, ułatwiających funkcjonowanie firmy. Nasz zarząd to duet stawiający na niestandardowe rozwiązania, które pomagają osiągać założone cele, wspierając codzienne działania pracowników.",
      timelineSectionContentA: "2006",
      timelineSectionContentB: "2017",
      timelineSectionContentC: "2019",
      timelineSectionContentD: "2021",
      awardsSectionContentA: "Nagrody",
      awardsSectionContentB: "Ostatnie lata",
      awardsSectionContentC: "to pasmo sukcesów",
      awardsSectionContentD:
        "Zdobyliśmy ponad 20 nagród przyznawanych przez konsumentów i niezależnych ekspertów.",
    },
    Live: {
      title: "#Live",
      subtitleA: "NEONAILPOLAND",
      subtitleB: "NEOMAKEUP_COSMETICS",
      buttonText: "Zobacz na ig",
      next: "Następna",
      prev: "Poprzednia",
    },
    Account: {
      title: "Informacje o koncie",
      userEmail: "E-mail użytkownika",
      posName: "Nazwa POS",
      posAddress: "Adres POS",
      posManager: "Regionalny kierownik sprzedaży",
      rejectedInspirations: "Odrzucone inspiracje",
      addedInspirations: "Dodane inspiracje",
    },
    Search: {
      title: "Wyszukiwanie dla",
      notFound: "Nie znaleziono wyników wyszukiwania",
      notFoundInfoSok:
        "Nie znaleziono wyników wyszukiwania w kategorii Standardy Obsługi Klienta. Upewnij się, że w frazie nie ma literówek i spróbuj ponownie",
      notFoundInfoInsp:
        "Nie znaleziono wyników wyszukiwania w kategorii Inspiracje. Upewnij się, że w frazie nie ma literówek i spróbuj ponownie",
      backToMainPage: "Powrót do strony głównej",
    },
    Footer: {
      info: "Wszystkie prawa zastrzeżone, wyłącznie do użytku wewnętrznego",
    },
    Swal: {
      errorTitle: "Ups, mamy problem!",
      errorText:
        "Wystąpił problem podczas ładowania pewnych danych. Jeśli uniemożliwia to korzystanie ze strony spróbuj załadować ją ponownie",
      errorBtn: "Rozumiem",
      infoTitle: "Sesja przedawniona!",
      infoText:
        "Zostałeś wylogowany ponieważ sesja się przedawniła. Zaloguj się ponownie.",
      logTitle: "Zaloguj się!",
      logText: "Aby kontynuować korzystanie z aplikacji musisz być zalogowany.",
    },
    Errors: {
      universalError: "Wystąpił błąd przy pobieraniu",
    },
    Regulations: {
      title: "Regulamin",
      closeText: "Zamknij",
      sectionA: {
        title: "Informacje ogólne",
        element1:
          "Niniejszy regulamin określa ogólne warunki oraz zasady korzystania z usług oferowanych za pośrednictwem bloga internetowego dostępnego pod adresem sok.cosmogroup.pl (daj]lej jako; Serwis), a także określa zasady świadczenia usług drogą elektroniczną przez Autora bloga.",
        element2:
          "Regulamin został wydany zgodnie z treścią art. 8 ust. 1 pkt. 1 ustawy z dnia 18 lipca 2002 roku o świadczeniu usług droga elektroniczną (tj. Dz. U. z 2020 roku poz. 344 z późn. zm.).",
        element3:
          "Celem prowadzenia bloga jest promocja informacji na temat prawidłowych standardów obsługi klientów korzystających ze stacjonarnych punktów obsługi klienta marki NEOANIL. ",
      },
      sectionB: {
        title: "Definicja",
        element1:
          "Serwis – blog internetowy dostępny pod adresem sok.cosmogroup.pl.",
        element2:
          "Użytkownik – każda osoba korzystająca z Serwisu lub też usług oferowanych przez Właściciela za pośrednictwem Serwisu;",
        element3:
          "Właściciel – podmiot posiadający prawa do Serwisu oraz wszelkich treści publikowanych za jego pośrednictwem, tj. Cosmo Group Sp. z o.o. Sp. k. z siedzibą w Poznaniu przy ul. Jasielskiej 10A, 60-476 Poznań, NIP: 9721241158, REGON: 302250849;",
        element4:
          "Regulamin – niniejszy „Regulamin bloga internetowego dostępnego pod adresem sok.cosmogroup.pl",
      },
      sectionC: {
        title: "Postanowienia ogólne i korzystania z Serwisu",
        element1:
          "Wszelkie prawa do Serwisu, w tym majątkowe prawa autorskie, prawa własności intelektualnej do jego nazwy, jego domeny internetowej, a także do treści publikowanych w Serwisie, wzorców, formularzy, logotypów zamieszczanych w Serwisie należą do Właściciela, a korzystanie z nich może następować wyłącznie w sposób określony i zgodny z Regulaminem.",
        element2:
          "Właściciel Serwisu publikuje treści za pośrednictwem Serwisu w celu przekazania Użytkownikom Serwisu preferowanych przez Właściciela zasad obsługi klientów za pośrednictwem Punktów Obsługi Stacjonarnej należących do  Właściciela",
        element3:
          'Właściciel w ramach Serwisu, stosuje mechanizm plików "cookies", które podczas korzystania przez Użytkowników z Serwisu, zapisywane są przez serwer Właściciela na dysku twardym urządzenia końcowego Użytkownika. Stosowanie plików "cookies" ma na celu poprawne działanie Serwisu na urządzeniach końcowych Użytkowników. Mechanizm ten nie niszczy urządzenia końcowego Użytkownika oraz nie powoduje zmian konfiguracyjnych w urządzeniach końcowych Użytkowników ani w oprogramowaniu zainstalowanym na tych urządzeniach. Każdy Użytkownik może wyłączyć mechanizm „cookies” w przeglądarce internetowej swojego urządzenia końcowego. Właściciel  wskazuje, że wyłączenie „cookies” może jednak spowodować utrudnienia lub uniemożliwić korzystanie z Serwisu.',
        element4:
          "Zakazane jest dostarczanie przez Użytkowników treści o charakterze bezprawnym oraz wykorzystywanie przez Użytkowników Serwisu w sposób sprzeczny z prawem, dobrymi obyczajami lub naruszający dobra osobiste osób trzecich.",
        element5:
          "Zabronione jest wykorzystywanie funkcjonalności Serwisu w celu prowadzenia przez Użytkowników działalności, która naruszałaby interes Właściciela lub działalności polegającej na zamieszczaniu treści nieprawdziwych lub wprowadzających w błąd. ",
        element6:
          "Przeglądanie i zapoznanie się z treścią Serwisu jest w pełni dobrowolne i nieodpłatne.",
      },
      sectionD: {
        title: "Zakres usług świadczonych przez Właściciela",
        element2:
          "usługi udostępnienia informacji o preferowanych standardach obsługi klientów za pośrednictwem Punktów Obsługi Stacjonarnej należących do Właściciela",
        element1: "Właściciela świadczy za pośrednictwem Serwisu:",
        element3:
          "usługi publikacji zdjęć przedstawiających wykonane przez Użytkowników stylizacje paznokci oraz po przeprowadzeniu ich weryfikacji przez Właściciela, usługi publikacji przesłanych zdjęć stylizacji",
      },
      sectionE: {
        title: "Dostęp do materiałów publikowanych w Serwisie",
        element1:
          "Każdy Użytkownik, po uprzednim zalogowaniu się ma dostęp do wszystkich materiałów informacyjnych publikowanych za pośrednictwem Serwisu. ",
        element2:
          "Po poprawnym zalogowaniu się, każdy Użytkownik ma równy dostęp do wszystkich treści publikowanych w ramach serwisu. ",
        element3:
          "Zabrania się kopiowania i rozpowszechniania w części lub całości, bez pisemnej zgody Właściciela oraz bez podania źródła jakichkolwiek treści publikowanych w Serwisie. ",
        element4:
          "Treści publikowane w Serwisie stanowią wyraz opinii oraz oceny Właściciela Serwisu. ",
      },
      sectionF: {
        title: "Publikacja inspiracji za pośrednictwem Serwisu",
        element1:
          "Za pośrednictwem Serwisu, Użytkownicy mogą opublikować zdjęcie wykonanej stylizacji paznokci.",
        element2:
          "Udostępnienie stylizacji następuje poprzez wypełnienie formularza zgłoszeniowego, udostępnionego w Serwisie. ",
        element3:
          "W celu przesłania zdjęcia stylizacji, Użytkownik zobowiązany jest podać następujące informacje:",
        element4: "swoje imię;",
        element5: "adres e-mail prywatny ",
        element6:
          "wykaz produktów marki NEOANIL wykorzystanych do stworzenia stylizacji, wraz ze wskazaniem numerów katalogowych.",
        element7:
          "Zdjęcie stylizacji musi spełniać następujące warunki techniczne:",
        element8: "maksymalny rozmiar – 5 MB;",
        element9: "format pliku: jpg, png, gif.",
        element10:
          "Zdjęcia stylizacji nie mogą promować produktów stanowiących konkurencję dla produktów oferowanych przez Właściciela. ",
        element11:
          "Publikacja zdjęć inspiracji nie może zawierać treści, które:",
        element12:
          "mogły zostać zamieszczone z zamiarem naruszenia praw osób trzecich;",
        element13:
          "mogą naruszać jakiekolwiek prawa osób trzecich, w tym prawa związane z ochroną praw autorskich i praw pokrewnych;",
        element14:
          "mogą zawierać treści obraźliwe, wulgarne lub stanowić groźbę karalną skierowaną do innej osoby;",
        element15: "mogą być sprzeczne z interesem Właściciela;",
        element16: "mogą w inny sposób naruszać postanowienia Regulaminu.",
        element17:
          "W przypadku naruszenia warunków określonych powyżej, Właściciel zastrzega sobie prawo odmowy publikacji zdjęcia w Serwisie.",
        element18:
          "Użytkownik przesyłając inspiracje za pośrednictwem Serwisu potwierdza, iż zgłoszona przez niego fotografia przedstawiająca inspirację jest jego dziełem do którego posiada pełnie praw autorskich i pokrewnych oraz, że zgłoszona praca jest wolna od wad prawnych i nie narusza żadnych praw osób trzecich, jak również dóbr osobistych osób trzecich.",
        element19:
          "Przesyłając inspirację zgodnie z § 6 ust. 3 Regulaminu, Użytkownik upoważnia Właściciela do nieograniczonego w czasie oraz terytorialnie korzystania z inspiracji poprzez jej publikowanie na następujących polach eksploatacji:",
        element20:
          "utrwalanie dowolnymi technikami, w tym w szczególności utrwalanie w całości i we fragmentach na wszelkich nośnikach audiowizualnych jak np. taśma światłoczuła, magnetyczna, celuloidowa, nośniki cyfrowe – DVD, CD, CD ROM, VCD, Mini Disc, płyta MP3, klisza fotograficzna, płyta analogowa, dysk optyczny, kości pamięci, i innych nośnikach pamięci;",
        element21:
          "zwielokrotnianie utrwaleń w całości i we fragmentach dowolnymi technikami, w tym drukarską, reprograficzną, światłoczułą, magnetyczną, cyfrową, optyczną, techniką zapisu komputerowego, na nośnikach audiowizualnych, wideofonicznych, komputerowych;",
        element22: "wprowadzenie do pamięci komputera;",
        element23: "wprowadzenie do lokalnych sieci komputerowych;",
        element24:
          "tłumaczenie, opracowanie, aranżacja, przystosowanie, zmiana układu lub jakiekolwiek inne zmiany w zapisie komputerowym, z prawem do zwielokrotnienia rezultatów tych zmian, z zachowaniem praw osoby, która te zmiany wykonała;",
        element25:
          "wprowadzenie do sieci teleinformatycznej np. Internet, wykonywanie kopii takich zapisów oraz ich rozpowszechnianie w sieciach teleinformatycznych;",
        element26:
          "rozpowszechnianie na stronach internetowych Właściciela, portalach społecznościowych Właściciela oraz na profilach Właściciela w portalach społecznościowych;",
        element27:
          "udostępnianie za pośrednictwem aplikacji mobilnej Właściciela.",
        element28:
          "Publikacja inspiracji za pośrednictwem Serwisu dokonywana na wniosek Użytkownika, i w związku z jej publikacją oraz udzieleniem licencji na korzystanie z przesłanej fotografii, Użytkownikowi nie przysługuje od Właściciela jakiekolwiek wynagrodzenie. ",
        element29:
          "Ostateczne decyzja odnośnie publikacji inspiracji w Serwisie należy wyłącznie do Właściciela i z tego tytułu, Użytkownikowi przysługuje uprawnienie do złożenia reklamacji. ",
      },
      sectionG: {
        title: "Reklamacje",
        element1:
          "Uczestnikowi przysługuje uprawnienie do złożenia reklamacji, w przypadku realizacji przez Właściciela usług będących  świadczonych za pośrednictwem Serwisu w sposób niezgodny z niniejszym Regulaminem. ",
        element2:
          "Uczestnik ma prawo do złożenia reklamacji, w terminie 30 dni od dnia uzyskania informacji o nieprawidłowej realizacji swoich obowiązków przez Właściciela. ",
        element3:
          "Reklamacje należy złożyć za pośrednictwem poczty elektronicznej, na adres e-mail: ",
        element3A:
          ", lub w formie listownej, poprzez przesłanie wypełnionego formularza reklamacyjnego na adres siedziby NEOANIL: Cosmo Group Sp.z o.o. Sp.k. ul. Jasielska 10A, 60-476 Poznań.",
        element4:
          "Właściciel ustosunkuje się do zgłoszenia reklamacyjnego w terminie 14 dni od dnia złożenia reklamacji oraz udzieli odpowiedzi Uczestnikowi w wybranej przez niego formie. ",
      },
      sectionH: {
        title: "Pliki cookies",
        element1:
          "Serwis wykorzystuje pliki cookies. Są to niewielkie pliki tekstowe wysyłane przez serwer www i przechowywane przez oprogramowanie komputera przeglądarki. Kiedy przeglądarka ponownie połączy się ze stroną, witryna rozpoznaje rodzaj urządzenia, z którego łączy się użytkownik. Parametry pozwalają na odczytanie informacji w nich zawartych jedynie serwerowi, który je utworzył. Cookies ułatwiają więc korzystanie z wcześniej odwiedzonych witryn.",
        element2:
          "Gromadzone przez pliki cookies informacje dotyczą adresu IP, typu wykorzystywanej przeglądarki, języka, rodzaju systemu operacyjnego, dostawcy usług internetowych, informacji o czasie i dacie, lokalizacji oraz informacji przesyłanych do witryny za pośrednictwem formularza kontaktowego.",
        element3:
          "Zebrane dane służą do monitorowania i sprawdzenia, w jaki sposób Użytkownicy korzystają z naszego Serwisu, aby usprawniać funkcjonowanie Serwisu zapewniając bardziej efektywną i bezproblemową nawigację. Monitorowania informacji o użytkownikach dokonujemy korzystając z narzędzia Google Analitics, które rejestruje zachowanie użytkownika na stronie.",
        element4: "W Serwisie wykorzystujemy następujące pliki cookies:",
        element5:
          "Cookies sesyjne: po zakończeniu sesji danej przeglądarki lub wyłączeniu komputera zapisane informacje są usuwane z pamięci urządzenia. Mechanizm cookies sesyjnych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z komputerów Klientów;",
        element6:
          "Cookies trwałe: są przechowywane w pamięci urządzenia końcowego Klienta i pozostają tam do momentu ich skasowania lub wygaśnięcia. Mechanizm cookies trwałych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z komputera Klientów",
        element7:
          "Mechanizm cookies jest bezpieczny dla komputerów Użytkowników. W szczególności tą drogą nie jest możliwe przedostanie się do komputerów Użytkowników wirusów lub innego niechcianego oprogramowania lub oprogramowania złośliwego. Niemniej w swoich przeglądarkach Użytkownicy mają możliwość ograniczenia lub wyłączenia dostępu plików cookies do komputerów. W przypadku skorzystania z tej opcji korzystanie ze Serwisu będzie możliwe, poza funkcjami, które ze swojej natury wymagają plików cookies.",
        element8:
          "Dodatkowe dane osobowe, jak adres e-mail, zbierane są jedynie w miejscach, w których Użytkownik wypełniając formularz wyraźnie wyraził na to zgodę. Powyższe dane zachowujemy i wykorzystujemy tylko do potrzeb niezbędnych do wykonania danej funkcji.",
      },
      sectionI: {
        title: "Ochrona danych osobowych",
        element1:
          "Zasady ochrony danych osobowych zamieszczone są w Polityce prywatności.",
      },
      sectionJ: {
        title: "Postanowienia końcowe",
        element1:
          "Właściciel ponosi odpowiedzialność z tytułu niewykonania lub wykonania w prawidłowo sposób swoich obowiązków, określnych w treści niniejszego Regulaminu.",
        element2:
          "Treść niniejszego Regulaminu może zostać utrwalona poprzez wydrukowanie, zapisanie na nośniku lub pobranie w każdej chwili z Serwisu. ",
        element3:
          "W przypadku powstania sporu na gruncie niniejszego Regulaminu, strony będą dążyły do rozwiązania sprawy polubownie. Prawem właściwym dla rozstrzygania wszelkich sporów powstałych na gruncie niniejszego Regulaminu jest prawo polskie.",
        element4:
          "Właściciel zastrzega sobie prawo zmiany niniejszego Regulaminu. Właściciel poinformuje o zmianie Regulaminu na 7 dni przed wejściem w życie zmienionego Regulaminu, poprzez zamieszczenie stosownej informacji w Serwisie. ",
        element5:
          "Niniejsza treść Regulaminu obowiązuje od dnia 01.04.2023 roku. ",
      },
    },
    Policy: {
      title: "Polityka prywatności",
      description:
        "Niniejsza Polityka prywatności określa zasady przetwarzania danych osobowych za pośrednictwem bloga _______________, dostępnego pod adresem _________________, stanowi wyraz dbałości właściciela bloga o bezpieczeństwo przetwarzanych za pomocą bloga danych osobowych, a także stanowi realizacje obowiązku informacyjnego określonego w art. 13 Rozporządzenia Parlamentu Europejskiego i Rady (UE) nr 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej jako: RODO). ",
      sectionA: {
        title: "Postanowienia ogólne",
        element1:
          "Administratorem danych osobowych, zbieranych za pomocą bloga, dostępnego pod adresem: _____________________________ (dalej jako: Serwis) jest Cosmo Group Sp. z o.o. Sp. k. z siedzibą przy ul. Jasielskiej 10a, 60 – 476 Poznań, wpisaną do Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000437232, NIP 9721241158 (dalej jako: Administrator). ",
        element2:
          "W związku z prowadzeniem Serwisu, Administrator będzie przetwarzał dane osobowe użytkowników podane w Serwisie przez osoby odwiedzające Serwis. ",
        element3:
          "Wszelkie dane osobowe zbierane za pośrednictwem Serwisu przetwarzamy zgodnie z przepisami prawa, a w szczególności z przepisami RODO. ",
        element4:
          "Użytkownicy Serwisu mogą skontaktować się z Administratorem za pośrednictwem poczty elektronicznej pisząc na adres:",
      },
      sectionB: {
        title:
          "Jakie dane osobowe, w jakich celach oraz na jakiej podstawie prawnej będziemy zbierać:",
        element1:
          "Administrator będzie przetwarzał następujące dane osobowe: imię, adres poczty elektronicznej oraz  dane zapisane w plikach cookies.",
        element2:
          "Dane osobowe użytkowników będą przetwarzane w następujących celach: ",
        element3:
          "umożliwienia korzystania z naszego Fanpage – podstawą prawną przetwarzania danych osobowych będzie zgoda użytkownika na przetwarzanie jego danych osobowych (art. 6 ust. 1 lit. a RODO),",
        element4:
          "umożliwienia dodania zdjęcia stylizacji za pomocą formularza udostępnionego w Serwisie – podstawą prawną przetwarzania danych osobowych będzie zgoda użytkownika na przetwarzanie jego danych osobowych (art. 6 ust. 1 lit. a RODO),",
        element5:
          "dostosowania naszego Serwisu do wymagań użytkowników – podstawą prawną przetwarzania danych osobowych będzie nasz uzasadniony prawnie interes (art. 6 ust. 1 lit. f RODO).",
      },
      sectionC: {
        title:
          "Przez jaki okres będziemy przetwarzać dane osobowe użytkowników Serwisu.",
        element1:
          "Dane osobowe użytkowników Fanpage będą przez nas przetwarzane przez okres, którego długość zależeć będzie od celu oraz podstawy prawnej, w ramach której dane osobowe zostały zebrane przez Administratora. Zgodnie z powyższym dane osobowe użytkowników będą przetwarzane:",
        element2:
          "W przypadku danych osobowych przetwarzanych na podstawie zgody użytkownika – dane osobowe będą przechowywane przez Administratora do czasu wycofania zgody użytkownika Serwisu;",
        element3:
          "W przypadku danych osobowych przetwarzanych na podstawie prawnie uzasadnionego interesu administratora – dane osobowe będą przechowywane przez Administratora do czasu obowiązywania prawnie uzasadnionego interesu Administratora. ",
      },
      sectionD: {
        title: "Komu będziemy udostępniać dane osobowe użytkowników Serwisu:",
        element1:
          "Dane osobowe, przetwarzane za pośrednictwem Serwisu będziemy udostępniać podmiotom, z którymi współpracujemy w celu prowadzenia naszego Serwisu tj. podmioty świadczące usługi hostingowe, współpracownicy. Podmiotom tym Administrator udostępnia dane osobowe użytkowników, wyłącznie w zakresie niezbędnym do prawidłowej realizacji usług oraz na warunkach zawartej umowy powierzenia przetwarzania danych, spełniającej warunki art. 28 RODO. ",
        element2:
          "Administrator może również udostępnić dane osobowe użytkowników Serwisu innym podmiotom uprawnionym do uzyskania tych danych, na podstawie powszechnie obowiązujących przepisów prawa lub też na podstawie przeczenia sądowego lub decyzji organu administracji publicznej. ",
        element3:
          "Innym podmiotom, niż to zostało wskazane powyżej, Administrator może udostępnić dane osobowe użytkowników, wyłącznie na podstawie wyraźnej zgody użytkowników Serwisu. ",
      },
      sectionE: {
        title: "Uprawnienia przysługujące użytkownikom Serwisu",
        element1:
          "Wszystkim użytkownikom serwisu, których dane osobowe są przetwarzane przez Administratora za pomocą Serwisu, przysługują następujące uprawnienia:",
        element2:
          "Prawo uzyskania dostępu do danych osobowych – prawo do uzyskania kopii swoich danych osobowych przetwarzanych przez Administratora na pośrednictwem Serwisu;",
        element3:
          "Prawo do sprostowania lub uzupełnienia danych osobowych – prawo do żądania sprostowania błędnych lub uzupełnienia niekompletnych danych osobowych;",
        element4:
          "Prawo do usunięcia danych osobowych (prawo do bycia zapomnianym) – prawo do żądania całkowitego usunięcia danych osobowych ze zbioru danych przetwarzanych przez Administratora;",
        element5:
          "Prawo do ograniczenia przechowywania danych osobowych – prawo do żądania wprowadzenia przez Administratora środków organizacyjnych oraz technicznych mających na celu ograniczenie osób posiadających dostęp do danych osobowych;",
        element6:
          "Prawo do sprzeciwu wobec przetwarzania danych osobowych – prawo do zgłoszenia sprzeciwu wobec dalszego przetwarzania danych osobowych za pośrednictwem Serwisu, ze względu na szczególny interes użytkownika;",
        element7:
          "Prawo do przeniesienia danych osobowych – prawo do żądania przeniesienia danych osobowych do innego administratora, wskazanego przez użytkownika Serwisu;",
        element8:
          "Prawo do wycofania zgody na przetwarzanie danych osobowych – prawo do wycofania w każdym czasie, udzielonej zgody, której przedmiotem było zezwolenie na przetwarzanie danych osobowych. Zgoda może być wycofana w każdym czasie, bez wpływu na zgodność przetwarzania danych w okresie poprzedzającym wycofanie zgody. ",
      },
      sectionF: {
        title: "Dobrowolność podania danych osobowych ",
        element1:
          "Podanie danych osobowych przetwarzanych za pośrednictwem Serwisu jest w pełni dobrowolne, przy czym ich nie podanie może utrudnić lub też uniemożliwić korzystanie z określonych funkcjonalności Serwisu.",
      },
      sectionG: {
        title: "Pliki cookies",
        element1:
          'Serwis używa niewielkich plików, zwanych cookies. Zapisywane są one przez Administratora na urządzeniu końcowym osoby odwiedzającej Serwis, jeżeli przeglądarka internetowa na to pozwala. Plik cookie zwykle zawiera nazwę domeny, z której pochodzi, swój „czas wygaśnięcia" oraz indywidualną, losowo wybraną liczbę identyfikującą ten plik. Informacje zbierane za pomocą plików tego typu pomagają dostosowywać treści oferowane przez Administratora, do indywidualnych preferencji i rzeczywistych potrzeb osób odwiedzających Sklep Internetowy. ',
        element2: "Administrator wykorzystuje dwa typy plików cookies:",
        element3:
          "Cookies sesyjne: po zakończeniu sesji danej przeglądarki lub wyłączeniu komputera zapisane informacje są usuwane z pamięci urządzenia. Mechanizm cookies sesyjnych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z komputerów Klientów.",
        element4:
          "Cookies trwałe: są przechowywane w pamięci urządzenia końcowego Klienta i pozostają tam do momentu ich skasowania lub wygaśnięcia. Mechanizm cookies trwałych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z komputera Klientów.",
        element5: "Administrator wykorzystuje cookies własne w celu:",
        element6:
          "analiz i badań oraz audytu oglądalności, a w szczególności do tworzenia anonimowych statystyk, które pomagają zrozumieć, w jaki sposób Klienci korzystają ze Serwisu, co umożliwia ulepszanie jej struktury i zawartości,",
        element7:
          "weryfikacji zainteresowania treściami publikowanymi za pomocą Serwisu.",
        element8: "Administrator wykorzystuje cookies zewnętrzne w celu:",
        element9:
          "zbierania ogólnych i anonimowych danych statycznych za pośrednictwem narzędzi analitycznych Google Analytics (administrator cookies zewnętrznego: Google Inc z siedzibą w USA).",
        element10:
          "Mechanizm cookies jest bezpieczny dla komputerów osób odwiedzających Serwis. W szczególności tą drogą nie jest możliwe przedostanie się do komputerów użytkowników końcowych wirusów lub innego niechcianego oprogramowania lub oprogramowania złośliwego. Niemniej w swoich przeglądarkach Użytkownicy końcowy mają możliwość ograniczenia lub wyłączenia dostępu plików cookies do komputerów. W przypadku skorzystania z tej opcji korzystanie ze Serwisu będzie możliwe, poza funkcjami, które ze swojej natury wymagają plików cookies.",
        element11:
          "Poniżej przedstawiamy jak można zmienić ustawienia popularnych przeglądarek internetowych w zakresie stosowania plików cookies:",
        element12: "przeglądarka Internet Explorer",
        element13: "przeglądarka Microsoft EDGE",
        element14: "przeglądarka Mozilla Firefox",
        element15: "przeglądarka Chrome i Chrome Mobile",
        element16: "przeglądarka Safari i Safari Mobile",
        element17: "przeglądarka Opera",
      },
      sectionH: {
        title: "Postanowienia końcowe",
        element1:
          "Niniejsza Polityka Prywatności może ulec zmianie, o czym Administrator poinformuje Klientów niezwłocznie, nie później jednak niż w terminie 3 dni przed wejściem w życie zmienionych zapisów.",
        element2:
          "Wszelkie pytania związane z Polityką Prywatności prosimy kierować na adres naszego Inspektora Ochrony Danych: Marek Furmańczak. Dane kontaktowe do IOD – adres e-mail: gdpr@cosmogroup.pl, numer telefonu: 513866696",
      },
    },
  },
  en: {
    LoginForm: {
      title: "customer service standards",
      logInInfo: "Log in to see content",
      logIn: "Log in",
      email: "E-mail address",
      password: "Password",
    },
    test: "test po en",
  },
  de: {
    LoginForm: {
      title: "customer service standards",
      logInInfo: "Log in to see content",
      logIn: "Log in",
      email: "E-mail address",
      password: "Password",
    },
    test: "test po de",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "pl",
  globalInjection: true,
  messages,
});

export default i18n;
