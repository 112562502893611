export const initialCustomJsFunctions = () => {
  Object.defineProperty(String.prototype, "firstSmall", {
    value: function () {
      return this.charAt(0).toLowerCase() + this.slice(1);
    },
    enumerable: false,
  });
};

export const addDebugDate = (name = "", data: unknown): boolean => {
  const debugMode = process.env.VUE_APP_DEBUG_MODE;
  if (debugMode == "1") {
    console.log(name, data);
  }
  return true;
};

export default { initialCustomJsFunctions, addDebugDate };
