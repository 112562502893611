<template>
  <router-view />
  <!-- <h1>Language Selector</h1>
  <LanguageSelector /> -->
</template>

<script setup>
// import LanguageSelector from "./components/languageSelector/LanguageSelector.vue";

setInterval(() => {
  let views = JSON.parse(localStorage.getItem("LocalViewsSession"));
  if (views.length > 0) {
    localStorage.setItem("LocalViewsSession", JSON.stringify([]));
  }
}, 1800000);
</script>

<style lang="scss">
@import "assets/scss/style.scss";
@import "./../node_modules/vue3-emoji-picker/dist/style.css";

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Lato, Avenir, Helvetica, Arial, sans-serif;
}

#app {
  font-family: Lato, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #262626;
  box-sizing: border-box;
  font-weight: 400;
  text-align: center;
}

#app * {
  box-sizing: border-box;
  font-family: Lato, Avenir, Helvetica, Arial, sans-serif;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

h1,
h2 {
  margin-block: 30px;
}
</style>
