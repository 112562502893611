import ApiService from "../../core/services/ApiService";
import JwtService from "../../core/services/JwtService";
import { useRouter } from "vue-router";

const router = useRouter();

type FormData = {
  name: string;
  password: string;
};

type User = {
  userId: number;
  isActive: boolean;
  isAdmin: boolean;
  lang: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  phone: string;
  posCode: string;
  posName?: {
    whsCode: string;
    whsName: string;
    street: string;
    zipCode: string;
    city: string;
    u_RKS: string;
  };
};

class UserService {
  public static user = {} as User;
  public static isAuth = !!JwtService.getToken();

  public static getUser = (): User => {
    return JSON.parse(localStorage.getItem("UserData") || "[]") || [];
  };

  public static getAdmin = (): boolean => {
    return this.getUser().isAdmin;
  };

  public static getAuth = (): boolean => {
    return this.isAuth;
  };

  public static setAuth = (data: { token: string; user: User }): void => {
    this.isAuth = true;
    this.user = data.user;
    JwtService.saveToken(data.token);
    ApiService.get("Pos/all?PageSize=400&CurrentPage=1")
      .then((resp) => {
        this.user.posName = resp.data.data[0].posList.find(
          (el: { whsName: string; whsCode: string }) => {
            return el.whsCode === data.user.posCode;
          }
        );
        const userSaveData = {
          userId: this.user.userId,
          posName: this.user.posName,
          name: this.user.name,
          surname: this.user.surname,
          isAdmin: this.user.isAdmin,
          email: this.user.email,
        };
        window.localStorage.setItem("LocalViewsSession", JSON.stringify([]));
        window.localStorage.setItem("UserData", JSON.stringify(userSaveData));
        window.localStorage.setItem(
          "PosArray",
          JSON.stringify(resp.data.data[0].posList)
        );
      })
      .catch((err) => {
        throw new Error(err);
      });

    ApiService.get("Products/productList").then((resp) => {
      window.localStorage.setItem(
        "ProductsData",
        JSON.stringify(resp.data.data)
      );
    });
  };

  public static purgeAuth = (): void => {
    this.isAuth = false;
    this.user = {} as User;
    JwtService.destroyToken();
  };

  public static verifyAuth = () => {
    const token = JwtService.getToken();
    if (token) {
      const tokenData = JwtService.parseTokenData(token);
      const time = Math.floor(Date.now() / 1000);
      if (tokenData.exp <= time) {
        this.purgeAuth();
      } else {
        ApiService.setHeader(token);
      }
    } else {
      this.purgeAuth();
    }
  };

  public static setUser = (token: string) => {
    const tokenParsed = JwtService.parseTokenData(token);
    ApiService.setHeader(token);
    return ApiService.get(`User/${tokenParsed.user_id}`)
      .then(({ data }) => {
        if (data.success == true) {
          const user = data.data[0];
          if (user.enabled == false) {
            router.push({ name: "errorPage" });
            return false;
          }
          this.setAuth({ token, user });

          return true;
        }
      })
      .catch((response) => {
        const error = {
          authUser: "Failed login",
        };
        if (response.data.success == false) {
          error.authUser = response.data.errorMessage;
        }
        return null;
      });
  };

  public static login = (credentials: FormData) => {
    return ApiService.post("Auth", credentials)
      .then(({ data }) => {
        if (data?.success == true) {
          const token = data.data[0].token;
          return this.setUser(token);
        }
      })
      .catch((response) => {
        const error = {
          authUser: "Failed login",
        };
        if (response.data.success == false) {
          error.authUser = response.data.errorMessage;
        }
        return null;
      });
  };
}

export default UserService;
