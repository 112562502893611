import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import UserService from "../core/services/UserService";
import ApiService from "../core/services/ApiService";
import i18n from "../core/plugins/i18n";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "defaultView",
    redirect: "/main-page",
    component: () => import("../views/layout/MainLayoutView.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/error",
        name: "errorPage",
        props: true,
        component: () => import("../views/errorPage/errorPageView.vue"),
      },
      {
        path: "/home",
        name: "home",
        component: HomeView,
      },
      {
        path: "/admin",
        name: "admin",
        component: () => import("../views/admin/AdminView.vue"),
        meta: {
          permits: "admin",
        },
        children: [
          {
            path: "/admin/addUser",
            name: "addUser",
            component: () => import("../views/admin/AddUserView.vue"),
          },
          {
            path: "/admin/editUser",
            name: "editUser",
            component: () => import("../views/admin/EditUserListView.vue"),
          },
          {
            path: "/admin/editUser/:id",
            name: "editUserId",
            component: () => import("../views/admin/EditUserView.vue"),
          },
          {
            path: "/admin/inspirations",
            name: "manageInspirations",
            meta: {
              category: "inspiration",
            },
            component: () =>
              import("../views/admin/ManageInspirationsView.vue"),
          },
        ],
      },
      {
        path: "/inspirations",
        name: "inspirations",
        meta: {
          category: "inspiration",
        },
        component: () => import("../views/inspirations/InspirationsView.vue"),
      },
      {
        path: "/inspirations/add",
        name: "addInspiration",
        meta: {
          category: "inspiration",
        },
        component: () =>
          import("../views/inspirations/InspirationsAddView.vue"),
      },
      {
        path: "/inspirations/edit/:id",
        name: "editInspiration",
        meta: {
          category: "inspiration",
        },
        component: () =>
          import("../views/inspirations/InspirationsAddView.vue"),
      },
      {
        path: "/inspirations/thank-you",
        name: "thankYouInsp",
        meta: {
          category: "inspiration",
        },
        component: () =>
          import("../views/inspirations/InspirationThankYouView.vue"),
      },
      {
        path: "/main-page",
        name: "mainPage",
        component: () => import("../views/mainPage/MainPageView.vue"),
      },
      {
        path: "/active-service",
        name: "activeService",
        component: () => import("../views/activeService/ActiveServiceView.vue"),
      },
      {
        path: "/consultant",
        name: "consultant",
        component: () => import("../views/consultant/ConsultantView.vue"),
      },
      {
        path: "/island",
        name: "island",
        component: () => import("../views/island/IslandView.vue"),
      },
      {
        path: "/difficult-situations",
        name: "difficult-situations",
        component: () =>
          import("../views/difficultSituations/difficultSituationsView.vue"),
      },
      {
        path: "/relations",
        name: "relations",
        component: () => import("../views/relations/RelationsView.vue"),
      },
      {
        path: "/needs",
        name: "needs",
        component: () => import("../views/needs/NeedsView.vue"),
      },
      {
        path: "/active-listening",
        name: "ActiveListening",
        component: () =>
          import("../views/activeListening/ActiveListeningView.vue"),
      },
      {
        path: "/advantages",
        name: "Advantages",
        component: () => import("../views/advantages/AdvantagesView.vue"),
      },
      {
        path: "/promotions",
        name: "Promotions",
        component: () => import("../views/promotions/PromotionsView.vue"),
      },
      {
        path: "/objections",
        name: "Objections",
        component: () => import("../views/objections/ObjectionsView.vue"),
      },
      {
        path: "/product",
        name: "Product",
        component: () => import("../views/product/ProductView.vue"),
      },
      {
        path: "/sale-close",
        name: "SaleClose",
        component: () => import("../views/saleClose/SaleCloseView.vue"),
      },
      {
        path: "/tough-cases",
        name: "ToughCases",
        component: () => import("../views/toughCases/ToughCasesView.vue"),
      },
      {
        path: "/blog-content",
        name: "blogContent",
        component: () => import("../views/allPosts/allPostsView.vue"),
      },
      {
        path: "/cosmo-team",
        name: "cosmoTeam",
        component: () => import("../views/cosmoTeam/CosmoTeamView.vue"),
      },
      {
        path: "/live",
        name: "live",
        component: () => import("../views/live/LiveView.vue"),
      },
      {
        path: "/account",
        name: "account",
        meta: {
          category: "inspiration",
        },
        component: () => import("../views/account/AccountView.vue"),
      },
      {
        path: "/quiz",
        name: "quiz",
        component: () => import("../views/quiz/QuizView.vue"),
      },
      {
        path: "/client-types",
        name: "clientTypes",
        component: () => import("../views/clientTypes/ClientTypesView.vue"),
      },
      {
        path: "/search/:query",
        name: "search",
        component: () => import("../views/search/SearchView.vue"),
      },
      {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
      },
    ],
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("../views/loginPage/LoginPageView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const { t } = i18n.global;

router.beforeEach(async (to, from, next) => {
  await UserService.verifyAuth();

  const requiresAuth = to.meta.middleware === "auth";
  const requiresAdmin = to.meta.permits === "admin";

  if (requiresAuth && !UserService.isAuth) {
    if (from.name === "sign-in") {
      next({ name: "sign-in" });
      ApiService.logOutMessage(
        1,
        t("Swal.logTitle"),
        "info",
        t("Swal.logText")
      );
    } else if (from.name) {
      next({ name: "sign-in" });
      ApiService.logOutMessage(
        1,
        t("Swal.infoTitle"),
        "info",
        t("Swal.infoText")
      );
    } else {
      next({ name: "sign-in" });
    }
  } else if (requiresAdmin && !UserService.getAdmin()) {
    stop();
    next(from.path);
  } else {
    next();
  }

  window.scrollTo({ top: 0, behavior: "smooth" });
});

export default router;
